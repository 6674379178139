body {
    background: #e2eddc;
}

.Collapsible-Checkout__trigger {
    font-size: 1.125em;
    color: #fff;
    position: relative;
    display: block;
    padding: 1.25rem 0;
    background: #344c2c;
    text-align: center;
    cursor: pointer;
    margin: 0 -1rem;
}

@media (min-width: 75em) {
    .Collapsible-Checkout__trigger {
        display: none;
    }

    .Collapsible-Checkout .Collapsible-Checkout__contentOuter {
        height: auto !important;
    }
}

.Collapsible-Checkout__trigger:after {
    font-size: 1.5em;
    position: absolute;
    right: 1rem;
    content: "\f107";
    top: 1rem;
    font-family: FontAwesome, sans-serif;
}

.Collapsible-Checkout__trigger.is-open,
.Collapsible-Checkout__trigger:hover {
    color: #fff;
}

.Collapsible-Checkout__trigger.is-open:after {
    content: "\f106";
    font-family: FontAwesome, sans-serif;
}
